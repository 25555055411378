<template>
  <div class="internetModelAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar
      :title="'设备型号详情'"
      v-watermark
    ></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-collapse
        v-model="activeNames"
        accordion
      >
        <van-collapse-item
          title="设备型号信息"
          name="1"
        >
          <van-cell
            center
            title="设备型号:"
            :value="internetModelInfo.model || '--'"
          />
          <van-cell
            center
            title="设备品牌:"
            :value="internetModelInfo.brand || '--'"
          />
          <van-cell
            center
            title="设备类型:"
            :value="internetModelInfo.equipmentType || '--'"
          />
          <van-cell
            center
            title="状态:"
          >
            <template #default>
              <span
                style="color: #00a854"
                v-if="internetModelInfo.status === 0"
              >正常</span>
              <span
                style="color: #d9001b"
                v-else-if="internetModelInfo.status === 1"
              >停用</span>
              <span v-else>--</span>
            </template>
          </van-cell>
          <van-cell
            center
            title="供应商:"
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="internetModelInfo.supplier"
                @click="companyClick(internetModelInfo.supplierCompanyId)"
              >
                {{ internetModelInfo.supplier }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            center
            title="设备协议:"
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="internetModelInfo.deviceProtocolFile && internetModelInfo.deviceProtocolFile.name"
                @click="fileClick(internetModelInfo.deviceProtocolFile.originalPath)"
              >
                {{ internetModelInfo.deviceProtocolFile.name + '.' + internetModelInfo.deviceProtocolFile.ext }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            center
            title="更新时间:"
            :value="internetModelInfo.updateDatetime | dateFormat"
          />
          <userJump
            title="更新人:"
            :userList="internetModelInfo.updateBy"
          ></userJump>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- <van-action-sheet
      description="请选择定位地图"
      v-model="showPosition"
      :actions="positionActions"
      cancel-text="取消"
      close-on-click-action
      @select="mapSelect"
    /> -->
  </div>
</template>

<script>

import { getInternetModelInfo } from '@/api/internetModel'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
import wx from 'weixin-js-sdk'
import index from '@/mixins'

export default {
  mixins: [index],
  name: 'internetModelAddressInfo',
  data() {
    return {

      activeNames: '1',
      internetModelInfo: {},
      loadingShow: false
    }
  },
  computed: {
    ...mapState('user', ['jurisdiction'])
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getInternetModelInfo(this.$route.params.id)
      if (data.data) {
        this.internetModelInfo = data.data
        wx.onMenuShareAppMessage({
          title: this.internetModelInfo.model, // 分享标题
          desc: `设备品牌:${this.internetModelInfo.brand ? this.internetModelInfo.brand : '--'
            }\n设备类型:${this.internetModelInfo.equipmentType
              ? this.internetModelInfo.equipmentType
              : '--'
            }\n系统类型:${this.internetModelInfo.systemType
              ? this.internetModelInfo.systemType
              : '--'
            }`, // 分享描述
          link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
          enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            alert('暂无分享权限')
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        this.loadingShow = false
      } else {
        this.loadingShow = false
        this.$toast.fail({
          message: '该物联设备型号已被删除!!',
          duration: 500
        })
      }
    } catch (error) {
      this.loadingShow = false
      if (
        error?.response?.status === 401
      ) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  },
  methods: {
    // 呼叫手机
    callOut(val) {
      Dialog.confirm({
        title: '信息确认',
        message: `确认呼叫${val}?`
      })
        .then(() => {
          window.location.href = `tel:${val}`
        })
        .catch(() => {
          // on cancel
        })
    },
    // 单位跳转
    companyClick(id) {
      if (id && this.jurisdiction.includes('supplier:info')) {
        this.$router.push(`/workTable/supplierAddressInfo/${id}`)
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    },
    // 文件跳转
    fileClick(path) {
      window.location.href = '/api/file' + path
    }
  }
}
</script>

<style lang="scss" scoped>
.internetModelAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .link {
      color: #1990ff;
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: auto;

      .van-collapse {
        background-color: transparent;

        .van-collapse-item {
          background-color: transparent;

          .van-collapse-item__title {
            background-color: white;
            font-weight: 700;
          }

          .van-collapse-item__wrapper {
            background-color: transparent;

            .van-collapse-item__content {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>
