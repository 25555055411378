import http from '@/units/request'
import axios from 'axios'
let cancelList = null
/** *
 * 获取物联设备型号列表
 * ***/
export const getInternetModelList = (data) => {
  if (cancelList !== null && data['page.current'] === 1) {
    cancelList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/is-equipment/model/v2/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      cancelList = c
    })
  })
}
/** *
 * 获取物联设备型号详细信息
 * ***/
export const getInternetModelInfo = (companyId) => {
  return http({
    url: `/is-equipment/model/v2/info/${companyId}`,
    method: 'get'
  })
}
